import React, { useState } from "react";
import { Space, Dropdown, Modal, Form, Input, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { reqUser } from "../../api";

function DropDown(props) {
    const username = localStorage.getItem("username");

    //Model and its Form
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();

    const handleOk = () => {
        form.submit();
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const onFinish = async (values) => {
        console.log("Success:", values);
        reqChangePassword(values);
        setIsModalOpen(false);
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };
    //Modal and its Form
    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.clear();
        navigate("/login", { replace: true });
    };
    const handleChangePassword = () => {
        setIsModalOpen(true);
    };

    const reqChangePassword = async (values) => {
        const formData = new FormData();
        formData.append("action", "password");
        formData.append("old_password", values.old_password);
        formData.append("new_password", values.new_password);
        const result = await reqUser(formData);
        console.log(result);
        if (result.data.status == "success") {
            navigate("/login", { replace: true });
        } else {
            message.warning("Change Failed, Please Check Your Password");
        }
    };

    const items = [
        {
            key: "1",
            label: (
                <a target='_blank' rel='noopener noreferrer' onClick={handleChangePassword}>
                    Change Password
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a target='_blank' rel='noopener noreferrer' onClick={handleLogOut}>
                    Logout
                </a>
            ),
        },
    ];

    return (
        <div>
            <Dropdown
                menu={{
                    items,
                }}
                arrow
                trigger={["click"]}>
                <a onClick={(e) => e.preventDefault()}>
                    <p className='userName'>
                        {username}
                        <DownOutlined style={{ fontSize: "12px" }} />
                    </p>
                </a>
            </Dropdown>
            <Modal title='Change Password' okText='Confirm' open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <Form
                    name='basic'
                    form={form}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'>
                    <Form.Item
                        label='Old Password'
                        name='old_password'
                        style={{ marginBottom: "30px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please input old password!",
                            },
                            {
                                pattern:
                                    /^(?=.*[!@#$%^&*()_+[\]{};':",.<>?])[A-Za-z0-9!@#$%^&*()_+[\]{};':",.<>?]{8,}$/,
                                message: "password must contain a special character and longer than 8",
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        label='New Password'
                        name='new_password'
                        style={{ marginBottom: "30px" }}
                        rules={[
                            {
                                required: true,
                                message: "Please input new password!",
                            },
                            {
                                pattern:
                                    /^(?=.*[!@#$%^&*()_+[\]{};':",.<>?])[A-Za-z0-9!@#$%^&*()_+[\]{};':",.<>?]{8,}$/,
                                message: "password must contain a special character and longer than 8",
                            },
                        ]}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}

export default DropDown;
